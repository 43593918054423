import { isValidPhoneNumber } from '../bulkUploads/phoneValidate';
import {
  WARNING_PH_NUMBER_INVALID
} from '../bulkUploads/logs';


export const validatePhNumber = (
  phoneNumber: string, updateLogs: (msg: string) => void
) => {
  try {
    const phoneNumberFormatted = (phoneNumber || '').replace('CELL', '').replace('MCEL', '').trim();
    const isValidPh = isValidPhoneNumber(phoneNumberFormatted)
    if (!isValidPh) {
      updateLogs(`${WARNING_PH_NUMBER_INVALID} ${phoneNumber}.`);
    }
    return !isValidPh ? null : phoneNumberFormatted;
  } catch (e) {
    return null;
  }
}

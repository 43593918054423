import pLimit from 'p-limit';
import { BearerT } from '../utils/auth/utils';
import { fetchExistingEncounters } from '../bulkUploads/symptoUtils';
import { PatientData } from './bulkUploadParse';
import { assignNewSurvey, fillEncounterData } from '../bulkUploads/symptoUtils';
import {
  PATIENT_FAILED,
  SKIPPING_NON_MERCY, NOT_DISCHARGED, NOT_EMERGENCY, SERVICE_INTRO_PATIENT, LAST_ENCOUNTER_30,
  NEW_PATIENT, ENROLL_IN_ENCOUNTER,
} from '../bulkUploads/logs';
import { createOrUpdatePatient } from './patientCreateOrUpdate';
import { generateEncounterData } from './encounterDataGenerate';
import { isServiceIntroEligible } from './isServiceIntroEligible';
import { isAlreadyExistingEncounter } from './isAlreadyExistingEncounter';

const limit = pLimit(10);

const SERVICE_INTRO_SURVEY_ID = '8f55b888-fb34-41ae-9026-ada9a27b09d1';

const createEncounter = async ({
  patientTvId, patientData, sendServiceIntro, auth, updateLogs, isNewPatient,
}: {
  patientTvId: string, patientData: PatientData, sendServiceIntro: boolean, auth: BearerT, isNewPatient: boolean, updateLogs: (log: string) => void
}) => {
  const formattedEncounterData = generateEncounterData({
    patientData,
    patientTvId,
    updateLogs,
  });
  const { patientSurveyId } = await assignNewSurvey({
    authCode: auth,
    patientTvId,
    genericSurveyId: '2e8737f9-fccd-45a2-afb4-317cd39acc42',
  });
  updateLogs(isNewPatient ? NEW_PATIENT : ENROLL_IN_ENCOUNTER);

  const { didFill } = await fillEncounterData({
    authCode: auth,
    patientSurveyId,
    patientTvId,
    encounterData: formattedEncounterData,
  });
  if (didFill && sendServiceIntro) {
    // now creating service intro
    await assignNewSurvey({
      authCode: auth,
      patientTvId,
      genericSurveyId: SERVICE_INTRO_SURVEY_ID,
    });
    updateLogs(`${SERVICE_INTRO_PATIENT} - ${patientTvId}`);
  }
  return didFill;
};

const getPatientEncounterStatus = async ({
  patientTvId, patientData, authToken, updateLogs,
}: {
  patientTvId: string, patientData: PatientData, authToken: BearerT, updateLogs: (log: string) => void
}): Promise<{
  createEncounter: boolean,
  sendServiceIntro: boolean,
}> => {
  const existingEncounters = await fetchExistingEncounters(authToken, patientTvId);
  const sendServiceIntro = isServiceIntroEligible(existingEncounters);
  if (sendServiceIntro === false) {
    updateLogs(LAST_ENCOUNTER_30);
  }

  const doesEncounterExist = await isAlreadyExistingEncounter({
    encounterData: existingEncounters,
    patientData,
    authCode: authToken,
  })

  return { createEncounter: doesEncounterExist === false, sendServiceIntro };
};

export const uploadSymptoData = async (
  userData: PatientData[],
  authCode: BearerT,
  updateData: (index: number, log: string) => void,
  finishPatient: () => void,
) => {
  await Promise.all(userData.map((patientData, index) => (limit(async () => {
    const updateLogs: (log: string) => void = (log) => {
      updateData(index, log);
    };
    if (patientData['Visit Facility'] !== 'CHI Mercy Medical Center') {
      updateLogs(SKIPPING_NON_MERCY);
      finishPatient();
      return;
    }
    if (patientData['Discharge Disposition'] !== 'Discharged to home or self care (routine discharge)'
      && patientData['Discharge Disposition'] !== 'Left without being seen.'
      && patientData['Discharge Disposition'] !== 'Elopement') {
      updateLogs(NOT_DISCHARGED);
      finishPatient();
      return;
    }
    // include emergency always, and include inpatient if visit type  is emergency
    // if inpatient, then reason for navigation is substance use / mat
    const isEmergency = patientData['Major Class'] === 'Emergency';
    const isSubstanceUse = patientData['Major Class'] === 'Inpatient' && patientData['Visit Type'] === 'Emergency';
    if (!isEmergency && !isSubstanceUse) {
      updateLogs(NOT_EMERGENCY);
      finishPatient();
      return;
    }

    try {
      const { patientTvId } = await createOrUpdatePatient({
        patientData, authToken: authCode, updateLogs,
      });

      const { createEncounter: shouldCreateEncounter, sendServiceIntro } = await getPatientEncounterStatus({
        patientTvId, patientData, authToken: authCode, updateLogs,
      });
      if (shouldCreateEncounter) {
        await createEncounter({
          patientTvId, patientData, sendServiceIntro, auth: authCode, updateLogs, isNewPatient: false
        });
      }
    } catch (e) {
      updateLogs(`${PATIENT_FAILED}. Reason ${e.message}...`);
      console.log(e);
    } finally {
      finishPatient();
    }
  }))));
}


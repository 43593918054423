import moment from 'moment';
import { PatientData } from './bulkUploadParse';
import { generateAuthHeader } from '../bulkUploads/symptoUtils';
import { symptoReq, BearerT } from '../utils/auth/utils';
import { validatePhNumber } from '../utils/phoneValidate';

const reloadPatient = async (auth: BearerT, patientTvId: string) => {
  (await symptoReq(`/providers/patients/cache/${patientTvId}`, {
    "method": "POST",
  }, generateAuthHeader(auth, true)));
};


export const updatePatient = async ({
  patientTvId, patientData, authToken, updateLogs
}: {
  patientTvId: string, patientData: PatientData, authToken: BearerT, updateLogs: (log: string) => void
}): Promise<true> => {

  // first, update all primary patient attributes
  const existingPatient = await symptoReq(`/providers/patients/${patientTvId}`, {
    method: 'GET',
  }, generateAuthHeader(authToken));
  const patientDataUpdate = {
    firstName: patientData['First Name'],
    lastName: patientData['Last Name'],
    profilePicture: null,
    dob: moment(patientData['DOB'], 'MM/DD/YYYY').format('MM/DD/YYYY'),
    sex: null,
    timeZone: 'America/Los_Angeles',
    mrn: existingPatient.mrn,
    notificationType: existingPatient.notificationType,
    phoneNumber: validatePhNumber(existingPatient.phoneNumber, updateLogs) || existingPatient.phoneNumber,
    email: existingPatient.email,
  };
  await symptoReq(`/providers/patients/${patientTvId}/update`, {
    method: 'POST',
    body: JSON.stringify({
      ...patientDataUpdate,
    }),
  }, generateAuthHeader(authToken, true));
  await reloadPatient(authToken, patientTvId);
  return true;
};

import moment from 'moment';
import { EncounterDataT } from '../bulkUploads/symptoUtils';

export const isServiceIntroEligible = (encounterData: EncounterDataT[]) => {
  const SERVICE_INTRO_TITLE = 'Service Intro, Mercy Roseburg';
  const lastServiceIntro = encounterData.find(({ name }) => name.includes(SERVICE_INTRO_TITLE));
  const lastServiceIntroWithin30Days = lastServiceIntro != null && moment().diff(moment(lastServiceIntro.startDate), 'days') < 30;
  // if last service intro sent less than 30 days ago, then don't send another
  if (lastServiceIntroWithin30Days) {
    return false;
  }
  return true;
};

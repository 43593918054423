import moment from 'moment';
import _ from 'lodash';
import { parseRace, parseEthnicity } from './dropdownUtils';
import { PatientData } from './bulkUploadParse';

const isInsuranceUmpqua = (insurancePlan: string) => (
  insurancePlan != null && (insurancePlan.toLowerCase().includes('umpqua') || insurancePlan.toLowerCase().includes('uha'))
)

export const getEncounterNumber = (patientData: PatientData) => {
  return patientData['Account Number'];
};

export const generateEncounterData = ({
  patientData,
  patientTvId,
  updateLogs,
}: {
  patientData: PatientData,
  patientTvId: string,
  updateLogs: (log: string) => void,
}) => {
  const isSubstanceUse = patientData['Major Class'] === 'In Patient' && patientData['Visit Type'] === 'Emergency';
  const payorList = patientData['Payers']?.split(';');
  const [payorListPartOne, payorListPartTwo] = payorList;
  updateLogs(isSubstanceUse
    ? `Encounter generated for ${patientTvId} (Substance Use)`
    : `Encounter generated for ${patientTvId}`);
  return [
    {
      questionTitle: 'Patient Address',
      value: patientData['Patient Address'],
    },
    {
      questionTitle: 'Race',
      value: parseRace(patientData.Race),
    },
    {
      questionTitle: 'Ethnicity',
      value: parseEthnicity(patientData.Ethnicity),
    },
    {
      questionTitle: (isInsuranceUmpqua(payorListPartOne) || !isInsuranceUmpqua(payorListPartTwo)) ? 'Insurance ID' : 'Secondary Insurance #',
      value: patientData.MRN,
    },
    {
      questionTitle: 'In Person PCN Encounter',
      value: 'No',
    },
    {
      questionTitle: 'Chief Complaint',
      value: patientData['Chief Complaint'],
    },
    {
      questionTitle: 'Discharge Diagnosis',
      value: patientData['Diagnoses'],
    },
    {
      questionTitle: 'Primary Care Provider Name',
      value: patientData['Care Team'],
    },
    {
      questionTitle: 'Umpqua Health Patient',
      value: 'true',
    },
    {
      questionTitle: 'High Utilizer?',
      value: Number(patientData['ED Visit Count 6 Mo']) >= 3 ? 'Yes' : 'No',
    },
    {
      questionTitle: 'Reason for Navigation',
      value: _.compact([
        Number(patientData['ED Visit Count 6 Mo']) >= 3 ? 'High Utilizer' : null,
        'Insurance type',
        isSubstanceUse ? 'Substance Use/MAT' : null,
      ]).join(','),
    },
    {
      questionTitle: 'Date of ED Visit / Referral',
      value: patientData['Discharge Date'] != null && patientData['Discharge Date'].trim().length > 0
        ? moment(patientData['Discharge Date'], 'MM/DD/YYYY').format('MM/DD/YYYY')
        : null,
    },
    {
      questionTitle: 'Provider / On-Site Coordinator Name',
      value: patientData['Attending Physician'],
    },
    {
      questionTitle: 'Primary Phone #',
      value: patientData['Patient Phone'],
    },
    {
      questionTitle: 'Email',
      value: patientData['Patient Email'],
    },
    {
      questionTitle: 'Insurance Plan',
      value: payorListPartOne,
    },
    {
      questionTitle: 'Secondary Insurance/Payor Plan',
      value: payorListPartTwo != null
        ? payorListPartTwo.trim()
        : null,
    },
    {
      questionTitle: 'Encounter #',
      value: getEncounterNumber(patientData),
    },
    {
      questionTitle: 'Primary Navigator',
      value: 'Natalie Franzese',
    },
    {
      questionTitle: 'Priority Level',
      value: '1) Low',
    },
    {
      questionTitle: 'Discharged',
      value: 'Yes',
    },
    {
      questionTitle: 'RX Prescribed',
      value: 'Unknown',
    },
    {
      questionTitle: 'Insurance Type / Payor',
      value: 'Managed Care Plan',
    },
    {
      questionTitle: 'Identification',
      value: 'Service Introduction',
    },
    {
      questionTitle: 'Status',
      value: 'Service Introduction',
    }

    // Referrals field is ignored
  ].filter(({ value }) => value != null && value.trim().length !== 0);
};
